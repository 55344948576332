//import logo from './logo.svg';
import './App.css';
import Amplify, { API } from 'aws-amplify'
import React, { Component} from 'react';
import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import logo from './images/Oct24QR.png';
import decpdf from './qrcodedec24.pdf';
import janpdf from './qrcodejan25.pdf';
import octpdf from './qrcodeoct24.pdf';
import novpdf from './qrcodenov24.pdf';

function App() {

    return (
      <div className = "App">
        
      <Authenticator>
      {({ signOut, user }) => (
        <div className="App">
          <p>
            Hello {user.username}, Welcome to BCSW Amarra
          </p>
          <button onClick={signOut}>Sign out</button>
          <ul></ul>
        </div>
      )}
      </Authenticator>

          <img src={logo} alt="Logo" width="250" height="250"/>

          <p>
           Oct 2024 Gate Code: <b>88990</b>
          <br></br>
          Pass and Code expire 11/10/2024
          </p>
            <a
            className="App-link"
            href="https://pass.myq.com/qQe4Ooqn20ecgeZXZUnSnACI6iOyseDEifvCZxnmEzDw"
            target = "blank"
            rel = "noopener noreferrer"
            >
              Follow this link to open gate by tapping your screen
            </a>
            <br></br>
            <br></br>

            <a href = {octpdf} target = "_blank">Print October 2024 QR Code Sheet</a>
            <br></br>
            <a href = {novpdf} target = "_blank">Print November 2024 QR Code Sheet</a>
                   <br></br>
             <a href = {decpdf} target = "_blank">Print December 2024 QR Code Sheet</a>
            <br></br>
            <a href = {janpdf} target = "_blank">Print January 2025 QR Code Sheet</a>
      </div>
      
    );
    
}
export default withAuthenticator(App);
